body {
  margin: 0;
  font-family: "Roboto Condensed", "Helvetica Narrow", "Arial Narrow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
}
